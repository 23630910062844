import React from 'react'

const NotFound = () => {
  return (
    <div className='full-page'>
        <h3>
            404 Page Not Found
        </h3>
    </div>
  )
}

export default NotFound